
    import * as app from '../../js/main.js';
    globalThis.app = app;

    let searchMenu = document.querySelector("#search");

    let openMenu = document.querySelector("#open-menu");
    let closeMenu = document.querySelector("#close-menu");

    openMenu.addEventListener('click', (e) => {
      app.toggleSearch();
    });

    closeMenu.addEventListener('click', (e) => {
      app.toggleSearch();
    });

    app.loadAutoComplete(searchMenu);
    searchMenu.addEventListener('itemSelected', function(e) {
      document.location = '/' + e.detail.slug;
    });
  